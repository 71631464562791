/*!

=========================================================
* Cryptelic
=========================================================

* Product Page: https://cryptelic.com
* Copyright 2020 Cryptelic

=========================================================

*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class Download extends React.Component {
  render() {
    return (
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <img alt="..." className="path" src={require("assets/img/path1.png")} />
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">Own Your Data</h2>
              <h4 className="description">
                Unlike other apps, Cryptelic allows you to you to choose where
                your messages are stored and the entire system is deployed,
                putting you in control of your data. You get conferencing and
                messaging applications hosted on your domain with privacy,
                control and the freedom to pick a specific country for your
                virtual private server.
              </h4>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-primary"
                color="primary"
                href="https://t.me/upfusion"
                role="button"
                size="lg"
              >
                Purchase Now
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Download;
