/*!

=========================================================
* Cryptelic
=========================================================

* Product Page: https://cryptelic.com
* Copyright 2020 Cryptelic

=========================================================

*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h1 className="title">Cryptelic</h1>
            </Col>
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink target="_blank" href="https://cryptelic.com">
                    Home Page
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink target="_blank" href="https://im.cryptelic.com">
                    Cryptelic IM
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    target="_blank"
                    href="https://platforms.cryptelic.com"
                  >
                    Platforms
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink target="_blank" href="mailto:info@cryptelic.com">
                    Company Email
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink target="_blank" href="https://t.me/upfusion">
                    Cloud Architect
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    target="_blank"
                    href="https://blog.cryptelic.com"
                    onClick={(e) => e.preventDefault()}
                  >
                    Blog
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
              <h3 className="title">Follow us:</h3>
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://www.instagram.com/cryptelic/"
                  id="tooltip622135962"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://www.facebook.com/cryptelic"
                  id="tooltip230450801"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip230450801">
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://www.linkedin.com/company/cryptelic"
                  id="tooltip318450378"
                  target="_blank"
                >
                  <i className="fab fa-linkedin" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip318450378">
                  Follow us
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
