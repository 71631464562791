/*!
=========================================================
* Cryptelic
=========================================================

* Product Page: https://cryptelic.com
* Copyright 2020 Cryptelic

=========================================================

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
  CardHeader,
  Form,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import Download from "views/IndexSections/Download.js";

const carouselItems = [
  {
    src: require("assets/img/feature1.png"),
    altText: "Slide 1",
    caption: "Custom Communication",
  },
  {
    src: require("assets/img/feature2.png"),
    altText: "Slide 2",
    caption: "Turning on Encryption",
  },
  {
    src: require("assets/img/feature3.png"),
    altText: "Slide 3",
    caption: "Notifications",
  },
];

class LandingPage extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }
  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="path"
              src={require("assets/img/blob.png")}
            />
            <img
              alt="..."
              className="shapes triangle"
              src={require("assets/img/triunghiuri.png")}
            />
            <img
              alt="..."
              className="shapes circle"
              src={require("assets/img/cercuri.png")}
            />
            <div className="content-center">
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  <h1 className="text-white">
                    Enhance privacy
                    <br />
                    <span className="text-white">with your own messenger</span>
                    <br />
                  </h1>
                  <p className="text-white mb-3">
                    Get your own secure instant messenger entirely under your
                    control with unlimited audio, video calls and conferencing
                  </p>
                  <div className="btn-wrapper mb-3">
                    <Button
                      className="btn-link"
                      color="success"
                      href="https://im.cryptelic.com/"
                      size="sm"
                    >
                      <i className="tim-icons icon-chat-33" /> Try Cryptelic for
                      Free
                    </Button>
                  </div>
                </Col>
                <Col lg="5" md="5">
                  <a href="https://im.cryptelic.com">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/cryptelic.png")}
                    />
                  </a>
                </Col>
              </Row>
            </div>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <h1 className="text-center">Key Benefits</h1>
                  <Row className="row-grid justify-content-center">
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-primary">
                          <i className="tim-icons icon-lock-circle" />
                        </div>
                        <h4 className="info-title">Secured</h4>
                        <hr className="line-primary" />
                        <p>
                          Solution is highly secured with end-to-end encryption
                          and cross-device verification. No one can decrypt your
                          messages, even server administrators.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-settings" />
                        </div>
                        <h4 className="info-title">Controllable</h4>
                        <hr className="line-warning" />
                        <p>
                          Messenger is entirely under your control. Use admin
                          panel to moderate users and rooms. Control type of
                          registration: public, closed, token-based.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-coins" />
                        </div>
                        <h4 className="info-title">Free</h4>
                        <hr className="line-success" />
                        <p>
                          Liberate your communication. Get unlimited audio,
                          video calls and conferencing. Safe from data-mining
                          and ads, subscription plans and other ways to siphon
                          money.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-palette" />
                        </div>
                        <h4 className="info-title">Customizable</h4>
                        <hr className="line-success" />
                        <p>
                          White-labelling, new design, adding desired features,
                          make the app your own. You will get a source code for
                          the messenger and ability to change it as you like.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-vector" />
                        </div>
                        <h4 className="info-title">Unified</h4>
                        <hr className="line-success" />
                        <p>
                          Channel data to and from tools you already use and
                          love, even bridging in users and groups from other
                          chat apps. Chat with everyone from one place.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-trophy" />
                        </div>
                        <h4 className="info-title">Quality</h4>
                        <hr className="line-success" />
                        <p>
                          As we also control the level of compression, the audio
                          and video quality is much better than on Slack,
                          Telegram or Viber. Conferencing tool can be also used
                          separately.
                          <br />
                          <a
                            className="info-title"
                            target="_blanc"
                            href="https://talk.cryptelic.com/"
                          >
                            Check it out
                          </a>
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-delivery-fast" />
                        </div>
                        <h4 className="info-title">Packaged</h4>
                        <hr className="line-warning" />
                        <p>
                          Once you'd like to have your own way to communicate
                          securely over the internet and don't want to pay for
                          custom design, we have a prepared package for you.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-support-17" />
                        </div>
                        <h4 className="info-title">Stable</h4>
                        <hr className="line-warning" />
                        <p>
                          Mature and reliable solution that can be used for a
                          long time after delively. Our team will will gladly
                          support you and help to resolve any situation.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg section-safe">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path5.png")}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col md="5">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/cryptelic-web.png")}
                  />
                  <Card className="card-stats bg-primary">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p"></CardTitle>
                          <p className="card-category text-white">Unlimited</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-warning">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p"></CardTitle>
                          <p className="card-category text-white">
                            Available in over 25 languages
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="card-stats bg-primary">
                    <CardBody>
                      <div className="justify-content-center">
                        <div className="numbers">
                          <CardTitle tag="p"></CardTitle>
                          <p className="card-category text-white">Free</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="px-md-5">
                    <hr className="line-success" />
                    <h3>More than simple chat</h3>
                    <p>
                      Whether you’re a team, a family, a community. Cryptelic is
                      where you’ll feel at home. Choose where to keep your data
                      by running your server. All data is end-to-end encrypted,
                      meaning no-one else can eavesdrop on your conversations,
                      not even server admins.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-key-25" />
                          </div>
                          <div className="ml-3">
                            <h6>Best end-to-end encryption available today</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-tap-02" />
                          </div>
                          <div className="ml-3">
                            <h6>
                              One touch voice and video calls and conferences
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-image-02" />
                          </div>
                          <div className="ml-3">
                            <h6>
                              Send, receive and view files in any conversation
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-send" />
                          </div>
                          <div className="ml-3">
                            <h6>Intelligent Notifications</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-molecule-40" />
                          </div>
                          <div className="ml-3">
                            <h6>Bridge other apps and networks</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Download />
          <div className="section">
            <Container className="align-items-center">
              <Row>
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">
                    What Devices will be Used?
                  </h1>
                  <h5 className="text-on-back">01</h5>
                  <p className="profile-description">
                    Your future messenger can be run everywhere you are. Access
                    it from your browser from anywhere. On mobile, use native
                    apps for iOS & Android. On desktop, use native apps for
                    macOS, Windows & Linux.
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Col>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Card className="card-plain">
                    <br />
                    <br />
                    <br />
                    <br />
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/feature-platforms.svg")}
                    />
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-between">
                <Col md="6">
                  <Row className="justify-content-between align-items-center">
                    <UncontrolledCarousel items={carouselItems} />
                  </Row>
                </Col>
                <Col md="5">
                  <h1 className="profile-title text-left">
                    Need Unique Features?
                  </h1>
                  <h5 className="text-on-back">02</h5>
                  <p className="profile-description text-left">
                    Dark theme or light theme - make Cryptelic your own! Think
                    of what features are super important for your specific case.
                    Feel free to request something unique and extraordinary. We
                    believe that people should have full control over their own
                    communication.
                  </p>
                  <div className="btn-wrapper pt-3">
                    <Button
                      className="btn-simple"
                      color="primary"
                      target="_blank"
                      href="https://t.me/upfusion"
                    >
                      <i className="tim-icons icon-book-bookmark" /> Need Help?
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <section className="section">
            <Container>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      <h1 className="profile-title text-left">
                        Let's have a talk
                      </h1>
                      <h5 className="text-on-back">03</h5>
                    </CardHeader>
                    <CardBody>
                      <Form name="contact" method="post">
                        <input type="hidden" name="form-name" value="contact" />
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Your Name</label>
                              <Input
                                placeholder="Allan"
                                name="name"
                                type="text"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email address</label>
                              <Input
                                placeholder="allan@cryptelic.com"
                                type="email"
                                name="email"
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Phone</label>
                              <Input
                                placeholder="001-12321345"
                                type="text"
                                name="phone"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Company</label>
                              <Input
                                placeholder="Cryptelic"
                                type="text"
                                name="company"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Message</label>
                              <Input
                                placeholder="Hello there!"
                                type="text"
                                name="message"
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-round float-right"
                          color="primary"
                          data-placement="right"
                          id="tooltip341148792"
                          type="submit"
                        >
                          Send message
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip341148792"
                        >
                          We'll reply to you soon!
                        </UncontrolledTooltip>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us at the office</h4>
                      <p>
                        London <br />
                        GB, <br />
                        England
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p>
                        Allan <br />
                        +44 888 888 88 <br />
                        Mon - Fri, 8:00-22:00
                      </p>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default LandingPage;
